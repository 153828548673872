import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  toggleRequired(e) {
    const endDateField = document.querySelector("#user_loan_officer_end_date");
    let userLoanOfficerActiveToggle = document.querySelector("#toggle-switch-user-active-field");
    
    if (userLoanOfficerActiveToggle.dataset.isActive == "false") {
      userLoanOfficerActiveToggle.dataset.isActive = true
      endDateField.value = "";
      endDateField.removeAttribute("required");
    } else {
      userLoanOfficerActiveToggle.dataset.isActive = false
      endDateField.setAttribute("required", true);
    }
  }
}