import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  // TODO: Implement a close all option for all open modals 
  // ID is MOdals random generated ID in view component. 
  // Selector is the class or ID passed in the open modal button or link that corresponds with the VC, so like 'data-modal-selector-value=".choose-language-modal"'
  static targets = [
    "modal", "modalCard", "modalBackground"
  ];
  static values = {
    selector: String, 
    exitAnimationClass: String,
    destroyOnExit: Boolean
  }
  connect() {
    if (this.modalTarget.classList.contains('is-active')) this.focusModal();
  }
  
  showModal() {
    document.body.setAttribute("modal-open", true);
    this.modalTarget.classList.add('is-active');
    this.focusModal();
  }
  
  focusModal() {
    super.focusFirstEl(this.modalTarget);
  }

  closeModal() {
    closeModal(
      this.modalTarget, 
      this.modalCardTarget, 
      this.modalBackgroundTarget, 
      this.exitAnimationClassValue, 
      this.destroyOnExitValue
    );
  }
}

export const closeModal = (modal = ".modal-component",
                            modalCard = ".modal-card", 
                            modalBackground = ".modal-background",
                            modalExitAnimationClass = "animate-zoom-out",
                            modalDestroyOnExit = false,
                            timeoutMS = 490
                          ) => 
{
  // Check if function call passed in a selector instead of an element (calling this function with a string or a specific nodeElement)
  if (typeof(modal) === "string") modal = document.querySelector(`${modal}`) 
  if (typeof(modalCard) === "string") modalCard = document.querySelector(`${modalCard}`)
  if (typeof(modalBackground) === "string") modalBackground = document.querySelector(`${modalBackground}`) 
  modalCard.classList.add(modalExitAnimationClass);
  modalBackground.classList.add('delay-fade-out');
  setTimeout(() => {
    modal.classList.remove('is-active');
    modalCard.classList.remove(modalExitAnimationClass);
    modalBackground.classList.remove('delay-fade-out');
    document.body.removeAttribute("modal-open");
    if (modalDestroyOnExit == true) {
      modal.remove();
    }
  }, timeoutMS);
}