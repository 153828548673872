import ApplicationController from "./application_controller";
import { ConfirmModal, ErrorModal } from "./index";

export default class extends ApplicationController {

  confirmRemove(e) {
    e.preventDefault();
    const showArchivedVal = document.querySelector("#show-archived-parties").value
    ConfirmModal.fire({
      title: `Are you sure you want to archive ${this.data.get('name')}?`,
      html: "",
      icon: "warning",
    }).then((result) => {
      if (result.value) {
        fetch(`/check_archived?user_sgid=${this.data.get("sgid")}`, { redirect: "error" })
          .then((r) => r.json())
          .then((data) => {
            if (data.can_archive) {
              return this.stimulate("PartiesReflex#archive", showArchivedVal);
            } else {
              ErrorModal.fire({
                title: "Party Archive Error",
                html: `This party is still attached to an active contract; please cancel the contract prior to archiving ${this.data.get('name')}.`,
                icon: "error",
              })
            }
          });
      }
    });
  }  
}
