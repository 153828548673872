import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'container', 'filePreview', 'imagePreview'];
  static values = { 
    previewImages: Boolean,
  }

  initialize() {
    this.inputId = this.inputTarget.id;
    this.validFormats = this.inputTarget.dataset['validFormats'];
    this.validFormatsArray = this.inputTarget.dataset['accepts'].split(', ');
    this.maxFileNumber = Number(this.inputTarget.dataset['maxFileNumber']);
    this.submitBtn = document.querySelector('input[type="submit"]') || document.querySelector('button[type="submit"]');
  }
  
  styleDragover() {
    this.containerTarget.classList.add("is-dragging");
  }

  styleDrop() {
    this.containerTarget.classList.remove("is-dragging");
    setTimeout(() => {
      if (this.inputTarget.files[0]) {
        this.containerTarget.classList.add("is-dropped"); 
        return;
      }
      this.containerTarget.classList.remove("is-dropped"); 
    }, 150);
  }

  styleStopDrag() {
    this.containerTarget.classList.remove("is-dragging");
  }

  addFile() {
    const files = [...this.inputTarget.files];
    let hasLargeFile = false;
    let skipFileCheck = false;
    let isValid = false;

    super.dismissToast(this.inputId); // First dismiss existing toasts relating to file uploads

    this.clearFilePreview();
    
    if (this.checkMaxFileNumber()) {
      this.cleanupInputAndRenderToast(`Exceeded max number of files that can be attached! Please try again with less than ${this.maxFileNumber} files attached.`)
      skipFileCheck = true;
    }

    if (skipFileCheck) return; // Break out since max number of files validation failed.

    files.every((file) => {
      if (this.hasInvalidFile(file.type)) {
        this.cleanupInputAndRenderToast(`Only ${this.validFormats} files can be uploaded. Please try again and upload only these file types.`)
        return false;
      }

      if (this.previewImagesValue) this.renderImagePreview(file);
      
      this.renderFileName(file.name);
      
      hasLargeFile = this.checkAttachedFileSize(file);
      
      isValid = true;
      return true;
    });
    if (isValid) this.submitBtn?.removeAttribute('disabled');
    if (hasLargeFile) super.renderToast(
      this.inputId, 'Since a large file is attached, uploading may take a bit of time to complete. <br> Please be patient.'
      );
    }
    
    renderFileName(fileName) {
      this.filePreviewTarget.insertAdjacentHTML('beforeend', `<div>${fileName}</div>`)
    }

  renderImagePreview(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.imagePreviewTarget.insertAdjacentHTML('beforeend', `<img src='${e.target.result}'>`);
    };
    reader.readAsDataURL(file);
    this.element.classList.add('has-images');
  }

  checkAttachedFileSize(file) {
    return file.size > 1000000;
  }

  checkMaxFileNumber() {
    return this.inputTarget.files.length > this.maxFileNumber;
  }

  clearInputValue() {
    this.inputTarget.value = ''; 
  }

  cleanupInputAndRenderToast(message) {
    super.renderToast(
      this.inputId, message
    );
    this.clearInputValue();
    this.clearFilePreview();
  }

  clearFilePreview() {
    this.filePreviewTarget.textContent = "";
    if (this.previewImagesValue) {
      this.element.classList.remove('has-images');
      this.imagePreviewTarget.textContent = "";
    }
  }
  
  hasInvalidFile(fileType) {
    let isInvalidFormat = true;
    
    this.validFormatsArray.forEach((format) => {
      if (format.includes(fileType)) {
        isInvalidFormat = false;
      } 
    })
    return isInvalidFormat;
  }
}
