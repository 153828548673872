import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  chooseRecruiterUser(e) {
    document.querySelector("[data-search-recruiter-name]").value =
      e.currentTarget.dataset.searchResultsName;
    document.querySelector("[data-search-recruiter-email]").value =
      e.currentTarget.dataset.searchResultsEmail;
    document.querySelector("[data-search-recruiter-type]").value =
      e.currentTarget.dataset.searchResultsType;

    this.updateContractAmount();

    e.currentTarget.parentNode.classList.add("vanish");
  }

  updateContractAmount() {
    const userType = document.querySelector('#contract_recruiter_role');
    const amount = userType.value == "User::LoanOfficer" ? 2500 : 10000
    document.querySelectorAll(".contract_amount").forEach(el => el.value = amount);
  }
}
