// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'
import Swal from "sweetalert2";

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components/design", true, /_controller\.js$/)
application.load(definitionsFromContext(context).concat(definitionsFromContext(contextComponents)))
StimulusReflex.initialize(application, { consumer, controller, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'

export const ConfirmModal = Swal.mixin({
  showCancelButton: true,
  showClass: { icon: 'animate animate-flip' },
  showCloseButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  cancelButtonColor: '',
  confirmButtonColor: '',
  reverseButtons: true,
})

export const ErrorModal = Swal.mixin({
  showClass: { icon: 'animate animate-flip' },
  showCloseButton: true,
  cancelButtonColor: '',
  confirmButtonColor: '',
  reverseButtons: true,
})