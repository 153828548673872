import ApplicationController from "../../../javascript/controllers/application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  static targets = ["moneyInput"]

  connect() {
    new Cleave(this.moneyInputTarget, {
      delimiter: ',',
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      swapHiddenInput: true,
      numeralDecimalScale: 2
    });    
  }
}