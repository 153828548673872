import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "emailInput",
    "passwordInput",
    "loginBtnContainer",
    "roleBtnContainer",
  ];

  showLoginForm() {
    this.roleBtnContainerTarget.classList.add("vanish");
    this.loginBtnContainerTarget.classList.remove("hidden");
    this.emailInputTarget.focus();
  }

  showPasswordField() {
    this.passwordInputTarget.type = 'text';
    setTimeout(() =>{
      this.resetPasswordField();
    }, 5000)
  }

  resetPasswordField() {
    this.passwordInputTarget.type = "password";
  }
}
