import ApplicationController from "./application_controller";
import { debounce } from "../packs/utils/debounce";

export default class extends ApplicationController {
  static targets = ["resultsRecruiterName", "resultsRecruiterEmail"];

  disconnect() {
    this.reset();
  }

  fetchResults = (e) => {
    this.query = e.target.value;

    if (this.query === "") {
      this.reset();
      return;
    }

    if (this.query === this.previousQuery) {
      return;
    }

    this.previousQuery = this.query;
    
    const url = `/search_user?query=${this.query}`;

    if (this.query.length > 2) {
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          e.target.parentElement.querySelector('div[data-search-target]').innerHTML = html;
        });
    }
  };

  debouncedFetchResults(e) {
    debounce(this.fetchResults, 500)(e);
  }

  reset() {
    this.resultsRecruiterNameTarget.innerHTML = "";
    this.resultsRecruiterEmailTarget.innerHTML = "";
    this.query = "";
    this.previousQuery = null;
  }

  // Clears dropdown search results when the email or name input is unfocused.
  clearRecruiterResults() {
    setTimeout(() => {
      this.resultsRecruiterNameTarget.innerHTML = "";
      this.resultsRecruiterEmailTarget.innerHTML = "";
    }, 350);
  }
}
