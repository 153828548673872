import ApplicationController from "./application_controller";
import { ConfirmModal } from "./index";

export default class extends ApplicationController {

  confirmRemove(e) {
    e.preventDefault();
    const showArchivedVal = document.querySelector("#show-cancelled-contracts").value
    ConfirmModal.fire({
      title: "Are you sure you want to cancel this contract?",
      html: "",
      icon: "warning",
    }).then((result) => {
      if (result.value) return this.stimulate("ContractsReflex#archive", showArchivedVal);
    });
  }  
}
