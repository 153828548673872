import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    pageId: String,
    dismissOnBlur: Boolean
  }
  
  connect() {
    this.sidenav = document.querySelector('#sidenav-component');
    this.openBtn = document.querySelector("#sidenav-open-btn");
    if (this.hasPageIdValue) this.applyActivePage(this.pageIdValue);
    if (this.hasDismissOnBlurValue) {
      this.listenForDismissClick(); 
      this.sidenav.classList.add("vanish");
    }
  }

  // Optional: Only attempts if sidenav component is rendered with a active_page: value in params. Applies is-active class to sidenav links to show user's current page location, utilizing the data-page attribute that matched the sidenav's link ID. 
  applyActivePage(page) {
    const sidenavLinkElement = document.getElementById(`${page}`);
    sidenavLinkElement?.classList.add('is-active');
  }

  closeSidenav() {
    this.sidenav.classList.remove("is-open");
    this.sidenav.classList.add("is-close");
    setTimeout(() => {
      this.sidenav.classList.add("vanish");
    }, 350);
  }

  openSidenav() {
    if (this.sidenav.classList.contains('is-open')) {
      this.closeSidenav();
      return;
    }
    this.sidenav.classList.remove('is-close');
    this.sidenav.classList.remove('vanish');
    setTimeout(() => {
      this.sidenav.classList.add('is-open');
      super.focusFirstEl(this.sidenav);
    }, 50);
  }

  listenForDismissClick() {
    // Optional: Only works if sidenav component is rendered with dismiss_on_click_out: true in params. 
    if (this.dismissOnBlurValue) {
      document.addEventListener("click", (e) => {
        if (!this.sidenav.contains(e.target) && !this.openBtn.contains(e.target) ) this.closeSidenav();
      });
    }
  }
}