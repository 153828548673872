// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("./stylesheets/tailwind");
require("./stylesheets/application.scss");

import "@fontsource/open-sans/";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
